import { Routes, Route } from 'react-router-dom';
import CreatorDashboard from './pages/CreatorDashboard';
import LoginPage from './pages/LoginPage';
import Home from './pages/Home';
import Success from './pages/Success';
import MyLicensedContent from './components/MyLicensedContent';
import LicenseDashboard from './pages/LicenseDashboard';
import CreatorCatalog from './pages/CreatorCatalog';
import Creator from './pages/Creator';
import RegisterUser from './pages/RegisterUser';
import Contact from './pages/Contact';

const AppRouter = () => {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            {/* <Route path="/creator_dashboard" element={<CreatorDashboard />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/content" element={<MyLicensedContent />} />
            <Route path="/success" element={<Success/>} />
            <Route path="/license_dashboard" element={<LicenseDashboard/>} />
            <Route path="/creator_catalog" element={<CreatorCatalog/>} />
            <Route path="/creator/:sys_id" element={<Creator />} />
            <Route path="/register" element={<RegisterUser />} /> */}
            <Route path="/contact" element={<Contact />} />
        </Routes>
    )
};

export default AppRouter;