'use client'

import { useState } from 'react'
import { Dialog, DialogPanel } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'


export default function Contact() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    return (
        <div className="bg-white">


            <div className="relative">
                <div className="mx-auto max-w-7xl">
                    <div className="relative z-10 pt-14 lg:w-full lg:max-w-2xl">
                        <svg
                            viewBox="0 0 100 100"
                            preserveAspectRatio="none"
                            aria-hidden="true"
                            className="absolute inset-y-0 right-8 hidden h-full w-80 translate-x-1/2 transform fill-white lg:block"
                        >
                            <polygon points="0,0 90,0 50,100 0,100" />
                        </svg>

                        <div className="relative px-6 py-24 sm:py-24 lg:px-8 lg:py-24 lg:pr-0">
                            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl">
                                {/* <div className="hidden sm:mb-10 sm:flex">
                                    <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-500 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                                        Anim aute id magna aliqua ad ad non deserunt sunt.{' '}
                                        <a href="#" className="whitespace-nowrap font-semibold text-blue-700">
                                            <span aria-hidden="true" className="absolute inset-0" />
                                            Read more <span aria-hidden="true">&rarr;</span>
                                        </a>
                                    </div>
                                </div> */}
                                <h1 className="text-3xl tracking-tight text-gray-900 sm:text-3xl">
                                    Contact Us
                                </h1>
                                <p className="mt-6 text-md leading-8 text-gray-600">
                                If you are interested in partnering with Collab, please drop up an email below!
                                </p>
                                <div className="mt-10 flex items-center gap-x-6 pb-64">
                                    <a
                                        href="mailto:joseph@collabmm.io?subject=New%20Collab%20Request"
                                        className="rounded-md bg-blue-700 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                    >
                                        Email us!
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-gray-50 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                    <img
                        alt=""
                        src="https://images.unsplash.com/photo-1555421689-491a97ff2040?q=80&w=2970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        className="aspect-[3/2] object-cover lg:aspect-auto lg:h-full lg:w-full"
                    />
                </div>
            </div>
        </div>
    )
}
