import React, { useContext, useEffect, useState } from 'react';
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import "./styles/NavigationBar.css"
import { AuthContext } from '../contexts/AuthContext';

const user = {
  name: 'Tom Cook',
  email: 'tom@example.com',
  imageUrl: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
};

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const NavigationBar = () => {
  const { isSignedIn, login, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();  // Use location directly within NavigationBar if needed


  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await axios.get('/auth/checkAuthentication', { withCredentials: true });
        if (response.status === 200) {
          login();
          //navigate('/creator_dashboard');
        }
      } catch (error) {
        //console.error('Error checking authentication', error);
      }
    };

    checkAuth();
  }, [login]);

  const handleLogout = async () => {
    try {
      console.log('Logging out...');
      await axios.post('/auth/logout', {}, { withCredentials: true });
      logout();
      navigate('/login');  // Ensure this gets executed
    } catch (error) {
      console.error('Error logging out', error);
    }
  };

  // const userNavigation = isSignedIn ? [
  //   { name: 'Your Profile', href: '/profile' },
  //   { name: 'Settings', href: '/settings' },
  //   { name: 'Sign out', onClick: handleLogout }
  // ]
  //   :
  //   [{ name: 'Login', href: '/login' },
  //     { name: 'Sign up', href: '/register' }
  //   ];
  const userNavigation = [];


  const navigation = [
    // { name: 'Creator Dashboard', href: '/creator_dashboard', current: location.pathname === '/creator_dashboard' },
    // //{ name: 'Login', href: '/login', current: location.pathname === '/login' },
    // { name: 'License Dashboard', href: '/license_dashboard', current: location.pathname === '/license_dashboard' },
    // { name: 'Creator Catalog', href: '/creator_catalog', current: location.pathname === '/creator_catalog' },
    // { name: 'Reports', href: '/reports', current: location.pathname === '/reports' },
  ];

  const navigateHome = () => {
    navigate('/home');
  };

  return (
    <Disclosure as="nav" className="bg-blue-950">
      <div className=" max-w-auto px-4 sm:px-6 lg:px-8">
        <div className="flex h-16 items-center justify-between">
          <div className="flex items-center">
            <div className="flex-shrink-0" onClick={navigateHome}>
              <h3 className="text-white font-logo m-0 cursor-pointer text-4xl">Collab</h3>
            </div>
            <div className="hidden md:block">
              <div className="ml-10 flex items-baseline space-x-4">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    aria-current={item.current ? 'page' : undefined}
                    className={classNames(
                      item.current
                        ? 'bg-blue-700 text-white'
                        : 'text-white hover:bg-blue-500 hover:bg-opacity-75',
                      'rounded-md px-3 py-2 text-lg font-medium no-underline',
                    )}
                  >
                    {item.name}
                  </a>
                ))}
              </div>
            </div>
          </div>
          <div className="hidden md:block">
            <div className="ml-4 flex items-center md:ml-6">
              {/* <button
                type="button"
                className="relative rounded-full bg-blue-600 p-1 text-blue-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-blue-600"
              >
                <span className="absolute -inset-1.5" />
                <span className="sr-only">View notifications</span>
                <BellIcon aria-hidden="true" className="h-6 w-6" />
              </button> */}

              {/* Profile dropdown */}
              {/* <Menu as="div" className="relative ml-3">
                <div>
                  <MenuButton className="relative flex max-w-xs items-center text-lg text-white">
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">Open user menu</span>
                    <span className="requestor_id">Menu</span>
                  </MenuButton>
                </div>
                <MenuItems
                  transition
                  className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none"
                >
                  {userNavigation.map((item) => (
                    <MenuItem key={item.name}>
                      {item.onClick ? (
                        <button
                          onClick={item.onClick}
                          className="block w-full px-4 py-2 text-left text-sm text-gray-700 underline-button"
                        >
                          <span className="requestor_id">{item.name}</span>
                        </button>
                      ) : (
                        <a
                          href={item.href}
                          className="block px-4 py-2 text-sm text-gray-700"
                        >
                          <span className="requestor_id">{item.name}</span>
                        </a>
                      )}
                    </MenuItem>
                  ))}
                </MenuItems>
              </Menu> */}
            </div>
          </div>
          <div className="-mr-2 flex md:hidden">
            {/* Mobile menu button */}
            {/* <DisclosureButton className="group relative inline-flex items-center justify-center rounded-mdp-2 text-blue-200 hover:bg-opacity-75 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-blue-600">
              <span className="absolute -inset-0.5" />
              <span className="sr-only">Open main menu</span>
              <Bars3Icon aria-hidden="true" className="block h-6 w-6 group-data-[open]:hidden" />
              <XMarkIcon aria-hidden="true" className="hidden h-6 w-6 group-data-[open]:block" />
            </DisclosureButton> */}
          </div>
        </div>
      </div>

      <DisclosurePanel className="md:hidden">
        <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
          {navigation.map((item) => (
            <DisclosureButton
              key={item.name}
              as="a"
              href={item.href}
              aria-current={item.current ? 'page' : undefined}
              className={classNames(
                item.current ? 'bg-blue-700 text-white' : 'text-white hover:bg-blue-500 hover:bg-opacity-75',
                'block rounded-md px-3 py-2 text-base requestor_id font-medium',
              )}
            >
              <span className="requestor_id">{item.name}</span>
            </DisclosureButton>
          ))}
        </div>
        <div className="border-t border-blue-700 pb-3 pt-4">
          {/* <div className="flex items-center px-5">
            <div className="flex-shrink-0">
              <img alt="" src={user.imageUrl} className="h-10 w-10 rounded-full" />
            </div>
            <div className="ml-3">
              <div className="text-base font-medium text-white">{user.name}</div>
              <div className="text-sm font-medium text-blue-300">{user.email}</div>
            </div>
            <button
              type="button"
              className="relative ml-auto flex-shrink-0 rounded-full border-2 border-transparent bg-blue-600 p-1 text-blue-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-blue-600"
            >
              <span className="absolute -inset-1.5" />
              <span className="sr-only">View notifications</span>
              <BellIcon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div> */}
          <div className="mt-3 space-y-1 px-2">
            {userNavigation.map((item) => (
              <DisclosureButton
                key={item.name}
                as="a"
                href={item.href}
                className="block rounded-md px-3 py-2 text-base font-medium requestor_id text-white hover:bg-blue-500 hover:bg-opacity-75"
              >
                <span className="requestor_id">{item.name}</span>
              </DisclosureButton>
            ))}
          </div>
        </div>
      </DisclosurePanel>
    </Disclosure>
  );
};

export default NavigationBar;