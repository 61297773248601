
import NavigationBar from './components/NavigationBar';


//Stripe
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import AppRouter from './AppRouter';
import { AuthProvider } from './contexts/AuthContext';

const stripePromise = loadStripe('pk_test_51PpXcB01jWWgWieskkWmvdpHXnzr50D4lMdoVnT847iZ3ynfHmtZY0XZNVlTKP1V9LOZpTpXsvkSEGLMGXaHgTHB00fEV7kj7X');

const App = () => {


  return (
    <AuthProvider>
      <Elements stripe={stripePromise}>
        <div className="min-h-full">
          <NavigationBar />
          <main>
            <AppRouter />
          </main>
        </div>
      </Elements>
    </AuthProvider>
  );
};

export default App;