// import "./styles/Home.css"

// const Home = () => {
//     return (
//         <>
//             <section className="hero">
//                 <div id="welcome-div">
//                     <div id="welcome-banner-div">
//                         <div id="banner-title-div">
//                             <h1 className="banner-title-text logo-text text-5xl font-logo">
//                                 Collab
//                             </h1>
//                         </div>
//                         <div id="banner-description-div" className="mt-">
//                             <h5 className="banner-text requestor_id text">
//                                 Collab is a Media Marketplace, committed to revolutionizing the relationship between the Media Industry and Creator Content/Live Streaming industry.
//                             </h5>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//             <section id="mission">
//                 <div id="mission-div">
//                     <div id="mission-title-div" className="text">
//                         <span className="text">
//                             Our mission
//                         </span>

//                     </div>
//                     <div id="mission-text-div" className="text">
//                         <span id="mission-text" className="text">
//                             We provide creators with the tools and services to maximize revenue from their content. Collab grants creators access to the hottest media to use in their content, and ensures the creators of that media get rewarded.
//                         </span>
//                     </div>

//                 </div>
//             </section>
//         </>
//     );
// };

// export default Home;

'use client'

import { useState } from 'react'
import { Dialog, DialogPanel } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'

const navigation = [
    { name: 'Product', href: '#' },
    { name: 'Features', href: '#' },
    { name: 'Marketplace', href: '#' },
    { name: 'Company', href: '#' },
    { name: 'Log in', href: '#' },
]

export default function Home() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    return (
        <div className="bg-white">


            <div className="relative">
                <div className="mx-auto max-w-7xl">
                    <div className="relative z-10 pt-14 lg:w-full lg:max-w-2xl">
                        <svg
                            viewBox="0 0 100 100"
                            preserveAspectRatio="none"
                            aria-hidden="true"
                            className="absolute inset-y-0 right-8 hidden h-full w-80 translate-x-1/2 transform fill-white lg:block"
                        >
                            <polygon points="0,0 90,0 50,100 0,100" />
                        </svg>

                        <div className="relative px-6 py-24 sm:py-24 lg:px-8 lg:py-24 lg:pr-0">
                            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl">
                                {/* <div className="hidden sm:mb-10 sm:flex">
                                    <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-500 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                                        Anim aute id magna aliqua ad ad non deserunt sunt.{' '}
                                        <a href="#" className="whitespace-nowrap font-semibold text-blue-700">
                                            <span aria-hidden="true" className="absolute inset-0" />
                                            Read more <span aria-hidden="true">&rarr;</span>
                                        </a>
                                    </div>
                                </div> */}
                                <h1 className="text-4xl tracking-tight text-gray-900 sm:text-6xl">
                                    Media to enrich your content and live streams
                                </h1>
                                <p className="mt-6 text-lg leading-8 text-gray-600">
                                Collab grants creators access to the hottest media to use in their content, and ensures the creators of that media get fairly rewarded
                                </p>
                                <p className="mt-6 text-lg leading-8 text-gray-600">
                                    Site launching soon!
                                </p>
                                <div className="mt-10 flex items-center gap-x-6">
                                    <a href="/contact" className="text-sm font-semibold leading-6 text-blue-900">
                                        Contact us <span aria-hidden="true" className="text-blue-900">→</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-gray-50 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                    <img
                        alt=""
                        src="https://images.unsplash.com/photo-1655718758203-65d3ac8430cf?q=80&w=3087&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        className="aspect-[3/2] object-cover lg:aspect-auto lg:h-full lg:w-full"
                    />
                </div>
            </div>
        </div>
    )
}
